import React, { memo } from 'react'
import TopShopBG from '../../assets/images/TOP_shop.png'
import Products from './Products/Products'

const Shop = () => (
  <>
    <div className='fluid'>
      <div className='position-relative overflow-hidden'>
        <img src={TopShopBG} alt='Shop' className='img-full' />
        <h3 className='text-white font-secondary position-absolute bottom-0 ps-3 d-block d-sm-none'>
          SHOP
        </h3>
      </div>
    </div>

    <div className='container text-center px-7'>
      <Products page='products' />
    </div>
  </>
)

export default memo(Shop)
