import * as React from 'react'

import Layout from '../components/layout'
// import Seo from '../components/seo'
import Shop from '../components/Shop/shop'

const ShopPage = () => {
  return (
    <Layout>
      {/* <Seo */}
      {/*  title='Our Shop' */}
      {/*  description='Feel free to get in touch. We will get back asap' */}
      {/* /> */}
      <Shop />
    </Layout>
  )
}

export default ShopPage
